@use "../colors";
@use "../media";

.homeContent {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  outline-offset: -32px;

  @include media.mobile {
    outline-offset: -18px;
  }

  .overlay {
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @include media.tablet {
      justify-content: center;
    }

    @include media.mobile {
      padding: 0;
      flex-direction: column;
    }


    .leftContainer, .rightContainer {
      flex: 0 1 45%;
    }

    .leftContainer {
      @include media.mobile {
        display: none;
        min-width: auto;
        max-width: 100%
      }

      @include media.tablet {
        display: none;
      }
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;

      @include media.mobile {
        padding: 2rem 2.5rem;
      }
    }

    .authContainer {
      align-self: center;
      max-width: 100%;
      height: fit-content;
      padding: 2.5rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--foreground);
      box-shadow: 10px 10px 0px 1px var(--foreground-secondary);
      -webkit-box-shadow: 10px 10px 0px 1px var(--foreground-secondary);
      -moz-box-shadow: 10px 10px 0px 1px var(--foreground-secondary);

      @include media.mobile {
        width: 100%;
        padding: 0;
      }
    }
  }

  .splashContainer {
    height: 100%;
    width: 50%;
    max-width: 1200px;
    background: url("/images/skingenerator_splash.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    @include media.tablet {
      width: 100%;
      background-size: contain;
      background-position-y: 30vh;
    }

    @include media.mobile {
      background: none;
    }
  }
}


