@use "styles/colors";
@use "styles/media";

.toggleAuthContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  width: 526px;
  height: 56px;
  border-radius: 8px;
  background: var(--background-light);

  @include media.mobile {
    width: 100%;
  }

  .toggleButton {
    max-width: 259px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    flex: 1;
    user-select: none;
    transition: all .2s ease-in-out;
    border: none;
    color: var(--greyed-out);
    text-shadow: none;
    background: transparent;

    &.active {
      background: #fff;
      color: var(--input);
      border-radius: 6px;
    }
  }
}
