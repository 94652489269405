@use "styles/colors";

.separator {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 32px 0;

  .separatorLine {
    flex: 1;
    border: 1px solid var(--border-light);
  }

  .separatorText {
    margin: 0 24px;
  }
}
