@use "styles/colors";
@use "styles/media";

.default {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 526px;
  height: 56px;
  border-radius: 8px;
  background: var(--accent);
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #FFF;

  @include media.mobile {
    width: 100%;
    padding: 16px 0;
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}

.white {
  composes: default;
  background: #FFF;
  color: var(--input);
}
