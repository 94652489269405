.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.primary {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loader {
    animation: rotate 1.3s infinite linear;
  }

  .loaderText {
    margin: 8px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}