@use "styles/colors";
@use "styles/media";

.default {
    width: 526px;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0.75rem;
    cursor: pointer;
    background: var(--accent);
    border: none;
    border-radius: 8px;

    /* font */
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: -0.045rem;

    @include media.mobile {
        width: 100%;
        padding: 12px 0;
    }

    span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    &.disabled {
        background: var(--secondary-foreground);
        cursor: not-allowed;
    }
}

.large {
    composes: default;
    font-size: clamp(14px, 6vw, 22px);
    font-weight: 700;
}
